import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import NotFound from './pages/NotFound';
import { Oops } from './pages/Oops';
import AuthRoutes from './common/utils/authGuards/AuthRoutes';
import { SessionTimeout } from './pages/SessionTimeout';
import { PipelineLayout } from './components/PipelineLayout';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<PipelineLayout />}>
        <Route path="*" element={<NotFound />} />
        <Route path="/oops" element={<Oops />} />
        <Route path="/session-ended" element={<SessionTimeout />} />
        <Route element={<AuthRoutes />}>
          <Route exact path="/" element={<Home />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
