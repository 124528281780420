import { useGetUserProfileQuery } from '../../redux/services/userApi';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { setuserFullName } from '../../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useGetUserFullName = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: userProfileResponse, error } = useGetUserProfileQuery(undefined, {
    skip: !isLoggedIn,
  });
  let userFullName = '';
  
  if (userProfileResponse) {
    if (
      !isEmpty(userProfileResponse.firstName) &&
      !isEmpty(userProfileResponse.lastName)
    ) {
      userFullName =
        userProfileResponse.firstName + ' ' + userProfileResponse.lastName;
    } else if (!isEmpty(userProfileResponse.firstName)) {
      userFullName = userProfileResponse.firstName;
    } else if (!isEmpty(userProfileResponse.lastName)) {
      userFullName =
        userProfileResponse.firstName + ' ' + userProfileResponse.lastName;
    }
  }

  useEffect(() => {
    userProfileResponse && dispatch(setuserFullName(userFullName));
  }, [isLoggedIn, userProfileResponse, userFullName]);

  useEffect(() => {
    if (error) {
      navigate('/oops'); 
    } else if (userProfileResponse) {
      dispatch(setuserFullName(userFullName));
    }
  }, [isLoggedIn, userProfileResponse, userFullName, error, navigate, dispatch]);


  return userFullName;
};
