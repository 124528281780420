import { useEffect, useState } from 'react';
import { useLoginStartMutation } from '../../../redux/services/userApi';
import { Navigate, Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { LoadingBackdrop } from '../authGuards/LoadingBackdrop';
import useAuthentication from '../../hooks/useAuthentication';
import { useSessionTimeoutAndRefresh } from '../../hooks/useSessionTimeoutAndRefresh';
import { useLogoutUserMutation } from '../../../redux/services/userApi';
import { useNavigate } from 'react-router-dom';
import { SessionTimeoutModal } from '../../../components/SessionTimeoutModal';
import { useDispatch } from 'react-redux';
import { setIsLoggedIn } from '../../../redux/slices/userSlice';

const AuthRoutes = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, isLoading, isError } = useAuthentication();
  const [loginStart] = useLoginStartMutation();
  const { inactive, countdown, setCountdown, setStartCountdown } =
    useSessionTimeoutAndRefresh();
  const [modalOpen, setModalOpen] = useState(false);
  const [logoutUser] = useLogoutUserMutation();
  const sessionStart = sessionStorage.getItem('sessionStart');
  const csrf = sessionStorage.getItem('x-keep-csrf');
  const navigate = useNavigate();

  const handleSignOut = () => {
    logoutUser(csrf);
    dispatch(setIsLoggedIn(false));
    setStartCountdown(false);
    setCountdown(60);
    navigate('/session-ended');
  };

  const handleContinue = () => {
    setModalOpen(false);
    setTimeout(() => {
      setStartCountdown(false);
      setCountdown(60);
    }, 1000);
  };

  useEffect(() => {
    if (isError) {
      navigate('/oops');
    }
  }, [isError, navigate]);

  useEffect(() => {
    if (isLoggedIn === false && !isLoading) {
      loginStart();
    }
    isLoggedIn &&
      !sessionStart &&
      sessionStorage.setItem('sessionStart', new Date().getTime());
  }, [isLoggedIn, loginStart, isLoading]);

  useEffect(() => {
    if (inactive) {
      setModalOpen(true);
      setStartCountdown(true);
    }
  }, [inactive]);

  useEffect(() => {
    if (countdown <= 1) {
      handleSignOut();
    }
  }, [countdown]);

  if (isLoggedIn === true) {
    return (
      <>
        {isLoading ? (
          <Box
            sx={{
              marginBottom: { xs: '12rem', md: '4rem' },
              marginTop: '8rem',
            }}
          >
            <LoadingBackdrop />
          </Box>
        ) : (
          <>
            <Outlet />
            <SessionTimeoutModal
              open={modalOpen}
              handleSignOut={handleSignOut}
              handleContinue={handleContinue}
              timeoutCountdown={countdown}
            />
          </>
        )}
      </>
    );
  }

  if (isError) return <Navigate to="/oops" />;
};

export default AuthRoutes;
