import { Box } from '@mui/material';

export const SectionImg = ({ img, desc }) => (
  <Box
    component="img"
    alt={desc}
    src={img}
    sx={{ display: 'block', m: 'auto', width: '12rem' }}
  />
);
