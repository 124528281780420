import apiEndPoints from '../../common/constants/api-endpoints';
import { baseApi } from './baseApi';

export const pipelineApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getKeepPipeline: builder.query({
      query: () => ({
        url: apiEndPoints.GET_KEEP_PIPELINE,
        method: 'POST',
      }),
      providesTags: ['Pipeline'],
    }),
  }),
});

export const { useGetKeepPipelineQuery } = pipelineApi;
