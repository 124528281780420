import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// lib-node-uwm-theme is a UWM private npm library
// Always use themes from this lib to implement DREAM design system in your app
import { uwmDarkTheme, uwmLightTheme } from 'lib-node-uwm-theme';
import Router from './Router';

const App = () => {
  const { mode } = useSelector(({ mode }) => mode);
  const theme = useMemo(
    () =>
      createTheme({
        ...(mode === 'light' ? uwmLightTheme : uwmDarkTheme),
      }),
    [mode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
};

export default App;
