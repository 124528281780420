const apiEndPoints = {
  GET_KEEP_PIPELINE: '/GetKeepPipeline',
  GET_USER_PROFILE: '/GetUserProfile',

  // oauth endpoints
  LOGIN_START: '/oauth-agent/login/start',
  LOGIN_END: '/oauth-agent/login/end',
  CLAIMS: '/oauth-agent/claims',
  REFRESH_TOKEN: '/oauth-agent/refresh',
  GET_USER_INFO: '/oauth-agent/userInfo',
  LOGOUT: '/oauth-agent/logout',
};

export default apiEndPoints;
