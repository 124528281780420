import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Typography, useTheme, Box } from '@mui/material';

export const SessionTimeoutModal = ({
  handleClose,
  open,
  handleSignOut,
  handleContinue,
  timeoutCountdown,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="500px"
    >
      <DialogTitle
        sx={{
          fontSize: '1.5rem',
        }}
        id="alert-dialog-title"
      >
        Session Timeout Warning
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          data-test="intactiveModalText"
          sx={{ color: theme.palette.text.primary }}
        >
          Your session will expire due to inactivity in{' '}
          <Typography
            component="span"
            sx={{
              color: theme.palette.warning.main,
              textDecoration: 'underline',
            }}
            data-test="intactiveModalCountdown"
          >
            {`${timeoutCountdown}`}
          </Typography>{' '}
          seconds. <br />
          Would you like to continue working?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={handleSignOut}
          autoFocus
          data-test="intactiveModalSignOutBtn"
        >
          Sign Out
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={handleContinue}
          autoFocus
          data-test="intactiveModalContinueBtn"
        >
          Keep Working
        </Button>
      </DialogActions>
    </Dialog>
  );
};
