import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import Display from '../../components/Display';
import Table from '../../components/Table';
import { useGetKeepPipelineQuery } from '../../redux/services/pipelineApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingBackdrop } from '../../common/utils/authGuards/LoadingBackdrop';
const Home = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(({ user }) => user.isLoggedIn);
  const userSet = useSelector(({ user }) => user.userFullName);
  const {
    data: pipelineData,
    isFetching: fetchingPipelineData,
    isError: fetchingPipelineIsError,
  } = useGetKeepPipelineQuery(undefined, {
    skip: !isLoggedIn || !userSet,
    disableCaching: true,
  });

  const [tabValue, setTabValue] = useState('Offers');
  let newTabValue = '';

  const handleTabValueChange = (newValue) => {
    switch (newValue) {
      default:
      case 0:
        newTabValue = 'Offers';
        break;
      case 1:
        newTabValue = 'Application in Progress';
        break;
      case 2:
        newTabValue = 'Application Complete';
        break;
    }

    if (newTabValue !== tabValue) {
      setTabValue(newTabValue);
    }
  };

  useEffect(() => {
    if (!fetchingPipelineData && fetchingPipelineIsError) {
      navigate('/oops');
    }
  }, [fetchingPipelineIsError, fetchingPipelineData]);

  return (
    <>
      {fetchingPipelineData ? (
        <LoadingBackdrop />
      ) : (
        <>
          {!fetchingPipelineData && pipelineData ? (
            <>
              <Box
                sx={{
                  mt: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Display
                  handleChange={handleTabValueChange}
                  isAccountExecutiveInd={pipelineData.isAccountExecutiveInd}
                />
              </Box>
              <Table tabValue={tabValue} pipelineData={pipelineData} />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default Home;
