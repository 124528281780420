import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import EmailLink from './EmailLink';
import { formatPhone } from '../../utils/formatting';

export const DetailDropdownPanel = ({ row: rowProp, index }) => {
  const { mode } = useSelector(({ mode }) => mode);
  const originalRate = rowProp.originalRatePercentage.toFixed(3);
  const newRate = rowProp.newRatePercentage.toFixed(3);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingLeft: '32px',
        paddingRight: '32px',
        margin: '0',
        width: '100%',
        backgroundColor: mode === 'light' ? 'rgba(8, 86, 139, 0.08)' : 'rgba(144,202,249,0.08)',
      }}
    >
      <Grid item xs={3} style={{ paddingLeft: '25px' }}>
        <Typography variant="h2" sx={{ fontSize: 24 }}>
          Offer Information
        </Typography>
      </Grid>
      <Grid item xs={9} sx={{ display: 'inline-grid', alignItems: 'center', paddingRight: '32px' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            fontSize: 14,
            justifyContent: 'flex-end',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Borrower: </Typography>
          <Typography>{rowProp.primaryBorrowerName}</Typography>
          <FontAwesomeIcon
            icon={faEnvelope}
            size="1x"
            fweight="900"
            style={{ marginTop: 4, marginLeft: 8 }}
          />
          <EmailLink
            label={rowProp.emailAddress}
            mailTo={'mailto:' + rowProp.emailAddress}
            index={index}
          />
          <FontAwesomeIcon
            icon={faPhone}
            size="1x"
            fweight="900"
            style={{ marginTop: 4, marginLeft: 8 }}
          />
          <Typography>{formatPhone(rowProp.phoneNumber)}</Typography>
        </Box>
      </Grid>
      <hr
        style={{
          width: '100%',
          marginLeft: '20px',
          marginRight: '20px',
          border:
            mode === 'light'
              ? 'solid 1px rgba(0,0,0,0.12)'
              : 'solid 1px rgba(255,255,255,0.12)',
        }}
      />
      <Grid item xs={2.4} style={{ paddingLeft: '25px', marginBottom: '8px' }} alignItems="stretch">
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          Original Rate
        </Typography>
        <Typography
          style={{ fontSize: 16, marginTop: '8px', marginBottom: '8px' }}
        >
          {originalRate}%
        </Typography>
      </Grid>
      <Grid item xs={2.4} style={{ paddingLeft: '25px', marginBottom: '8px' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          Est. New Rate
        </Typography>
        <Typography
          style={{ fontSize: 16, marginTop: '8px', marginBottom: '8px' }}
        >
          {newRate}%
        </Typography>
      </Grid>
      <Grid item xs={2.4} style={{ paddingLeft: '25px', marginBottom: '8px' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          Property Type
        </Typography>
        <Typography
          style={{ fontSize: 16, marginTop: '8px', marginBottom: '8px' }}
        >
          {rowProp.propertyType}
        </Typography>
      </Grid>
      <Grid item xs={2.4} style={{ paddingLeft: '25px', marginBottom: '8px' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          New Loan Purpose
        </Typography>
        <Typography
          style={{ fontSize: 16, marginTop: '8px', marginBottom: '8px' }}
        >
          {rowProp.purposeType}
        </Typography>
      </Grid>
      <Grid item xs={2.4} style={{ paddingLeft: '25px', marginBottom: '8px' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          New Loan Product
        </Typography>
        <Typography
          style={{ fontSize: 16, marginTop: '8px', marginBottom: '8px' }}
        >
          {rowProp.productType}
        </Typography>
      </Grid>
    </Grid>
  );
};
