import { useState, useEffect } from 'react';
import {
  useLogoutUserMutation,
  useRefreshMutation,
} from '../../redux/services/userApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsLoggedIn } from '../../redux/slices/userSlice';

export const useSessionTimeoutAndRefresh = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const increment = 1;
  const intervalTime = 1000;
  const timeout = 1800; // 30 minutes
  const refreshTreshold = 3000; // 50 minutes
  const [time, setTime] = useState(0);
  const [inactive, setInactive] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [countdown, setCountdown] = useState(60);
  const [startCountdown, setStartCountdown] = useState(false);
  const sessionStart = parseInt(sessionStorage.getItem('sessionStart'));
  const [refreshToken, { error: refreshError }] = useRefreshMutation();
  const [logoutUser] = useLogoutUserMutation();
  const csrf = sessionStorage.getItem('x-keep-csrf');

  const updateExpireTimer = () => {
    setTime(0);
  };

  const startRefreshToken = async (csrf) => {
    const refreshAttempt = await refreshToken(csrf);
    if (refreshError || refreshAttempt.error) {
      logoutUser(csrf);
      dispatch(setIsLoggedIn(false));
      navigate('/session-ended');
    } else sessionStorage.setItem('sessionStart', new Date().getTime());
  };

  useEffect(() => {
    if (time > timeout) {
      setInactive(true);
    } else setInactive(false);
    const interval = setInterval(() => {
      setTime((time) => time + increment);
    }, intervalTime);
    return () => {
      clearInterval(interval);
    };
  }, [time, inactive]);

  useEffect(() => {
    if (startCountdown) {
      if (countdown >= 1 && countdown <= 60) {
        const interval = setInterval(() => {
          setCountdown((countdown) => countdown - increment);
        }, intervalTime);
        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [countdown, inactive, startCountdown]);

  useEffect(() => {
    const now = new Date().getTime();
    const secondsSinceLastRefresh = Math.floor((now - sessionStart) / 1000);
    if (secondsSinceLastRefresh >= refreshTreshold) {
      startRefreshToken(csrf);
    }
    const interval = setInterval(() => {
      setRefresh((refresh) => refresh + increment);
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [refresh]);

  useEffect(() => {
    updateExpireTimer();
    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    for (let event in events) {
      window.addEventListener(events[event], updateExpireTimer);
    }

    return () => {
      for (let event in events) {
        window.removeEventListener(events[event], updateExpireTimer);
      }
    };
  }, []);

  return { inactive, countdown, setCountdown, setStartCountdown };
};
