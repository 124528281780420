import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from './services/baseApi';
import appReducer from './app/appSlice';
import modeReducer from './slices/modeSlice';
import userReducer from './slices/userSlice';

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    app: appReducer,
    mode: modeReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});

export default store;
