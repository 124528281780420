import apiEndPoints from '../../common/constants/api-endpoints';
import { baseApi } from './baseApi';

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    loginStart: builder.mutation({
      query: () => ({
        url: apiEndPoints.LOGIN_START,
        method: 'POST',
      }),
      transformResponse: (response) => {
        const url = response.authorizationRequestUrl;
        window.location.replace(url);
      },
    }),
    loginEnd: builder.mutation({
      query: (pageUrl) => ({
        url: apiEndPoints.LOGIN_END,
        method: 'POST',
        body: JSON.stringify({
          pageUrl,
        }),
      }),
      transformResponse: async (response) => {
        const csrf = response.csrf;
        sessionStorage.setItem('x-keep-csrf', csrf);
        return response;
      },
    }),
    refresh: builder.mutation({
      query: (csrf) => ({
        url: apiEndPoints.REFRESH_TOKEN,
        method: 'POST',
        headers: {
          'x-keep-csrf': csrf,
        },
      }),
    }),
    getUserInfo: builder.query({
      query: () => ({
        url: apiEndPoints.GET_USER_INFO,
        method: 'GET',
      }),
    }),
    logoutUser: builder.mutation({
      query: (csrf) => ({
        url: apiEndPoints.LOGOUT,
        method: 'POST',
        headers: {
          'x-keep-csrf': csrf,
        },
      }),
      transformResponse: async (response) => {
        sessionStorage.removeItem('x-keep-csrf');
        sessionStorage.removeItem('sessionStart');
      },
    }),
    getClaims: builder.query({
      query: () => ({
        url: apiEndPoints.CLAIMS,
        method: 'GET',
      }),
    }),
    getUserProfile: builder.query({
      query: () => ({
        url: apiEndPoints.GET_USER_PROFILE,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginStartMutation,
  useLoginEndMutation,
  useGetUserInfoQuery,
  useLogoutUserMutation,
  useGetClaimsQuery,
  useGetUserProfileQuery,
  useRefreshMutation,
} = userApi;
