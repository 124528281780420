import { AppBar, Toolbar, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import lightLogo from '../Keep_Color UWM_updated.svg';
import darkLogo from '../Keep_White UWM 1.svg';

const Header = () => {
  const { mode } = useSelector(({ mode }) => mode);
  const userFullName = useSelector((state) => state.user.userFullName);
  const logo = mode === 'light' ? lightLogo : darkLogo;
  const color =
    mode === 'light' ? 'rgba(23, 25, 35, 0.87)' : 'rgba(255, 255, 255, 1)';
  const backgroundColor =
    mode === 'light' ? 'rgba(255, 255, 255, 1)' : '#252525';

  return (
    <>
      <AppBar>
        <Toolbar
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            backgroundColor: { backgroundColor },
          }}
        >
          <img
            src={logo}
            id="keepLogo"
            alt="keepLogo"
            style={{
              height: '3rem',
              width: '10rem',
            }}
          />
          <Typography variant="h5" sx={{ color: { color } }}>
            {userFullName ? `User: ${userFullName}` : null}
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
