import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiEndPoints from '../../common/constants/api-endpoints';

const getUrl = () => {
  if (
    process.env.REACT_APP_API_BASE_URL === undefined ||
    process.env.REACT_APP_API_BASE_URL === null
  ) {
    return '/api';
  }
  return process.env.REACT_APP_API_BASE_URL;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: getUrl(),
  prepareHeaders(headers) {
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('Access-Control-Expose-Headers', '*');
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  mode: 'cors',
  credentials: 'include',
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  const csrf = sessionStorage.getItem('x-keep-csrf');
  const OAuthEndpoints = ['loginStart', 'logout', 'refresh', 'logoutUser'];
  const isOAuthEndpoint = OAuthEndpoints.includes(api?.endpoint);
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error && !isOAuthEndpoint) {
    if (result?.error?.status === 401) {
      const refreshResult = await baseQuery(
        {
          url: apiEndPoints.REFRESH_TOKEN,
          method: 'POST',
          headers: {
            'x-keep-csrf': csrf,
          },
        },
        api,
        extraOptions,
      );
      if (refreshResult.meta?.response?.ok === true && refreshResult.meta?.response?.status === 204 ) {
        result = await baseQuery(args, api, extraOptions);
      } else {
           await baseQuery(
            {
              url: apiEndPoints.LOGOUT,
              method: 'POST',
              headers: {
                'x-keep-csrf': csrf,
              },
            },
            api,
            extraOptions,
          );
          return result
        }
    } else {
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
