import oopsImg from '../assets/oopsImg.png';
import { PageTemplate } from '../components/PageTemplate';

export const Oops = () => {
  const returnToLanding = () => {
    window.location.replace(window.location.origin);
  };

  return (
    <PageTemplate
      title="Refresh To Continue"
      descText={
        'Sorry, we’ve encountered an issue on our end trying to load the page.'
      }
      descText2={'You can get back to work by refreshing below.'}
      img={oopsImg}
      imgDesc={'image for refresh page'}
      buttonText={'Refresh Page'}
      onClick={returnToLanding}
    />
  );
};
