import { useNavigate } from 'react-router-dom';
import { PageTemplate } from '../components/PageTemplate';

export const SessionTimeout = () => {
  const navigate = useNavigate();
  const returnToLanding = () => {
    navigate('/');
  };
  return (
    <>
      <PageTemplate
        title="Session Timeout"
        descText={'You have been signed out due to inactivity.'}
        buttonText={'Sign In'}
        onClick={returnToLanding}
      />
    </>
  );
};
