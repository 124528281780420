import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mode: 'light',
};

// app slice(reducer)
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

// export actions to update state. These are automatically generated by redux toolkit
export const { setMode } = appSlice.actions;

// export reducer to configureStore in redux/store.js
export default appSlice.reducer;
