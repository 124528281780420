import { useEffect } from 'react';
import { useLoginEndMutation } from '../../redux/services/userApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsLoggedIn } from '../../redux/slices/userSlice';
import { useGetUserFullName } from './useGetUserFullName';

const useAuthentication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginEnd, { data: loginEndResponse, isLoading, isError }] =
    useLoginEndMutation();

  const isLoggedIn = loginEndResponse?.isLoggedIn;
  const { userFullName } = useGetUserFullName();

  useEffect(() => {
    loginEnd(window.location.href);
  }, [loginEnd]);

  useEffect(() => {
    !isLoading && dispatch(setIsLoggedIn(isLoggedIn));
  }, [isLoggedIn, isLoading, loginEndResponse]);

  useEffect(() => {
    isError && navigate('/oops');
  }, [isError, loginEnd, isLoggedIn]);

  useEffect(() => {
    const rootUrl = window.location.origin;
    if (
      isLoggedIn === true &&
      (window.location.href.includes('signin-oidc') ||
        window.location.href.includes('undefined') ||
        window.location.href.includes('code'))
    )
      window.history.replaceState(null, '', rootUrl + '/');
  }, [loginEndResponse]);

  return { isLoggedIn, isLoading, isError, userFullName };
};

export default useAuthentication;
