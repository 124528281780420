import { Link } from 'react-router-dom';

const EmailLink = ({ mailTo, label, id }) => {
  return (
    <Link
      id={`emailLink${id}`}
      to="#"
      onClick={(e) => {
        window.location.href = mailTo;
        e.preventDefault();
      }}
      style={{ color: '#90caf9' }}
    >
      {label}
    </Link>
  );
};

export default EmailLink;
