import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const DetailPanelIcon = () => {
  const [open, setOpen] = useState(false);
  const [dropdownIcon, setDropdownIcon] = useState(faCaretDown);
  const { mode } = useSelector(({ mode }) => mode);

  const handleDropdownClick = (e) => {
    setOpen(!open);
    if (!open) {
      setDropdownIcon(faCaretUp);
    } else {
      setDropdownIcon(faCaretDown);
    }
  };

  const color = mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.56)';

  return (
    <FontAwesomeIcon
      icon={dropdownIcon}
      onClick={(e) => {
        handleDropdownClick(e);
      }}
      value={open}
      size="xl"
      fweight="900"
      color={color}
    />
  );
};
